import toWebPage from '~/app/lib/utils/toStructuredData/toWebPage';
import toArtist from '~/app/lib/utils/toStructuredData/toArtist';
import toTrack from '~/app/lib/utils/toStructuredData/toTrack';
import toImage from '~/app/lib/utils/toStructuredData/toImage';

export default ({
  website,
  toBreadcrumb,
  title,
  description,
  artistName,
  artistPagePath,
  artistImageUrl,
  trackName,
  baseUrl,
  imageUrl: trackImageUrl,
  pagePath: trackPagePath,
  pageUrl: trackPageUrl,
}) => {
  if (!trackName) return;

  const primaryImage = toImage({
    url: trackImageUrl,
    title: artistName,
  });

  const breadcrumb = toBreadcrumb([
    {
      name: artistName,
      pagePath: artistPagePath,
    },
    {
      name: trackName,
      pagePath: trackPagePath,
    },
  ]);

  const artistImage = toImage({
    url: artistImageUrl,
    title: artistName,
  });

  const artist = toArtist({
    name: artistName,
    url: `${baseUrl}${artistPagePath}`,
    imageId: artistImage && artistImage['@id'],
  });

  const webPage = toWebPage({
    url: trackPageUrl,
    title,
    description,
    primaryImageId: primaryImage && primaryImage['@id'],
    breadcrumbId: breadcrumb['@id'],
    websiteId: website['@id'],
  });

  const track = toTrack({
    url: trackPageUrl,
    name: trackName,
    artistId: artist && artist['@id'],
    imageId: primaryImage && primaryImage['@id'],
    webPageId: webPage['@id'],
  });

  return [primaryImage, breadcrumb, webPage, artist, track];
};
