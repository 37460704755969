import { useEffect, useRef } from 'react';

import { localizeTrack, refreshTrack } from '~/app/lib/store/tracks/actions';
import { selectUserIsBot } from '~/app/lib/store/session/selectors';
import { useAppLoading } from '~/app/components/NextApp/lib/CoreUi';
import { SelectedTrack } from '~/app/lib/store/tracks/types';
import { useSelector, useDispatch } from '~/app/lib/store/redux';

/**
 * A react hook that takes care of common item page checks
 */
const useTrackChecks = (track: SelectedTrack | undefined) => {
  const didRefreshRef = useRef(false);
  const didLocalizeRef = useRef(false);
  const userIsBot = useSelector(selectUserIsBot);
  const dispatch = useDispatch();

  // show the app loading indicator whenever the track is in a 'loading' state
  useAppLoading()(track?.isLoading);

  useEffect(() => {
    if (!track || track.isLoading) return;

    // don't make any api requests if user is bot to save api quotas
    if (userIsBot) return;

    const needsLocalize = track.hasLinksForUserCountry === false;
    const needsRefresh = track.isStale;
    const trackId = track.id;

    if (needsLocalize && !needsRefresh && !didLocalizeRef.current) {
      dispatch(localizeTrack({ trackId }));
      // flag that localize was attempted to prevent infinite loop if api request fails
      didLocalizeRef.current = true;
      return;
    }

    if (needsRefresh && !didRefreshRef.current) {
      dispatch(refreshTrack({ trackId }));
      // flag that refresh was attempted to prevent infinite loop if api request fails
      didRefreshRef.current = true;
    }
  }, [track]);
};

export default useTrackChecks;
