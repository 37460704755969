import removeUndefinedKeys from '~/app/lib/utils/removeUndefinedKeys';

export default ({ url, imageId, name, artistId, webPageId }) =>
  name &&
  removeUndefinedKeys({
    '@type': 'MusicRecording',
    '@id': `${url}#track`,
    url,
    name,
    image: {
      '@id': imageId,
    },
    // TODO: add this
    // inAlbum: albumPath,
    // dateCreated: releaseDate,
    subjectOf: {
      '@id': webPageId,
    },
    byArtist: {
      '@id': artistId,
    },
  });
